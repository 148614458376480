<template>
  <div>
    <div class="cases">

      <div class="product_title">
        <div class="product_tit">
          <div class="tit1">3D可视化</div>
          <div class="tit2"></div>
        </div>

      </div>
      <div class="caselist" v-html="con">

      </div>
    </div>
  </div>
</template>

<script>
import { getbannerdetail } from "@/http/api/user";
export default {
  data() {
    return {
      con: "",
    };
  },
  created() {
    this.getcompany();
  },
  methods: {
    async getcompany() {
      let params = {
        barId: 29,
      };
      const data = await getbannerdetail(params);
      this.con = data.data.content;
      var address = "http://36.152.65.166:10008";
      // var imgReg = /<img.*?(?:>|\/>)/gi;
      //  var srcReg = /src=['"]?([^'"]*)['"]?/i;
      //  this.con.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi, function(match, p) {
      //    console.log(p);
      //     return `<img src=' ${address+p}'/>`;
      //   })
      var reg = new RegExp("/dev-api");
      this.con = this.con.replace(
        /<img [^>]*src=['"]([^'"]+)[^>]*>/gi,
        function (match, p) {
          return `<img src=' ${address + p.replace(reg, "")}'/>`;
        }
      );
    },
  },
};
</script>

<style>
</style>
<style lang="scss" scoped>
.cases {
  .caselist {
    width: 1400px;
    margin: 0 auto;
    font-weight: 400;
    font-size: 20px;
    line-height: 40px;
    text-align: justify;
    color: #000000;
    text-indent: 40px;
    ul {
      width: 100%;
      margin-bottom: 100px;
      margin-top: 50px;
      display: flex;
      flex-wrap: wrap;
      li {
        height: 340px;
        width: 360px;
        background-color: #e8eaf2;
        text-align: center;
        margin-right: 35px;
        margin-top: 30px;

        .casetit {
          height: 80px;
          line-height: 80px;
          font-size: 20px;
        }
        .caseimg {
          width: 100%;
          height: 260px;
          box-sizing: border-box;
          border: 1px solid gray;
          z-index: 222;
          img {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            border: 1px solid white;
            z-index: 1;
          }
        }
      }
      li:hover {
        cursor: pointer;
        .casetit {
          background-color: #0088ea;
          color: white;
          font-weight: bold;
        }
      }
    }
  }
}
.product_title {
  width: 1500px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .product_tit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .tit1 {
      font-size: 30px;
      font-weight: bold;
      margin-bottom: 13px;
    }
    .tit2 {
      width: 60px;
      height: 4px;
      background-color: #0088ea;
    }
  }
}
</style>
